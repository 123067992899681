export default function useProductList() {
  const fields = ['title', 'slug',
    { key: 'listPrice', thStyle: { width: "3%" } },
    { key: 'inStock', thStyle: { width: "3%" } },
    'productStatus',
    { key: 'brand', thStyle: { width: "5%" } },
    'actions']


  function returnOrder(status) {
    if (status == 200) {
      return 'danger'
    } else if (status == 1) {
      return 'warning'
    }
    else if (status == 100) {
      return 'success'
    }
    else {
      return 'primary'
    }
  }

  return {
    returnOrder,
    fields
  };
}
