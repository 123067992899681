var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('label',[_vm._v("entries")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('b-button',{attrs:{"to":{
                name: 'create-product',
              },"variant":"primary"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add Product")])])],1)])],1)],1),[(_vm.products != null)?_c('b-table',{staticStyle:{"text-align":"center"},attrs:{"busy":_vm.loader,"per-page":_vm.perPage,"show-empty":"","items":_vm.products,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('loader')],1)]},proxy:true},{key:"cell(productStatus)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",staticStyle:{"min-width":"90px","max-width":"90px"},attrs:{"pill":"","variant":("light-" + (data.item.productStatus == 1 ? 'success' : 'danger'))}},[_vm._v(" "+_vm._s(data.item.productStatus == 1 ? "Active" : "Inactive")+" ")])]}},{key:"cell(brand)",fn:function(data){return [(data.item.brands != null)?_c('span',[_vm._v(_vm._s(data.item.brands.title))]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticStyle:{"padding":"0.5rem"},attrs:{"variant":".btn-flat-dark","to":{
              name: 'ProductEdit',
              params: {
                id: data.item.id,
              },
            }}},[_c('edit-icon',{staticClass:"custom-class text-primary",attrs:{"size":"1.5x"}})],1),_c('b-button',{staticStyle:{"padding":"0.5rem"},attrs:{"variant":".btn-flat-dark"},on:{"click":function () {
                if (data.item.productStatus == 1) {
                  _vm.handleProductDisable(data.item.id);
                }
              }}},[_c('x-circle-icon',{staticClass:"custom-class text-warning",attrs:{"size":"1.5x"}})],1),_c('b-button',{staticStyle:{"padding":"0.5rem"},attrs:{"variant":".btn-flat-dark"},on:{"click":function($event){return _vm.handleDelete(data.item.id)}}},[_c('trash-2-icon',{staticClass:"custom-class text-danger",attrs:{"size":"1.5x"}})],1)]}}],null,false,3076405358)}):_vm._e()],_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-start\n          ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.skip + 1)+" to "+_vm._s(_vm.skip + 10)+" of "+_vm._s(_vm.rows)+" entries")])]),_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-end\n          ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }