import Swal from 'sweetalert2';


export function confirmationPop(title,showCancel,confirmButtonText,callBack){
    Swal.fire({
        title:title ,
        showCancelButton: showCancel,
        confirmButtonText: confirmButtonText,
      }).then(async (result) => {
        if (result.isConfirmed) {
            callBack()
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
}